@import '../variables.scss';
@import '../tables/alertStatus.scss';

$details-header: #606d84;
$details-title: #939393;
$details-verified: #00af2b;
$details-text: #2a303b;
$details-fontSize: $font-size-main;
$exist-color: #289800;

.detailsContainer {
  color: $details-text;
  font-size: $details-fontSize;

  & h3 {
    color: $details-header;
    font-weight: bold;
    font-size: $details-fontSize;
    text-transform: uppercase;
  }
}

.detailsTitle {
  color: $details-title;
}

@mixin alignBtnRight($padRight: 0, $marBtnRight: 3px) {
  padding-right: $padRight;

  button {
    float: right;
    margin-right: $marBtnRight;
  }
}
