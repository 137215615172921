$button-bg: #0275d8;
$button-title: white;
$button-text-size: 14px;
$button-small-text-size: 12px;

.main {
  padding: 8px 15px 8px 15px;
  color: $button-title;
  font-size: $button-text-size;
  background: $button-bg;
}

.mainBold {
  font-weight: bold;
}

.mainSmall {
  padding: 5px 15px 5px 15px;
  font-size: $button-small-text-size;
}
