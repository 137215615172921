@import '../sidebar.module.scss';

$font-weight: normal;
$offsetMenuText: 53px;

.subnav {
  min-height: 1vh;
  margin-bottom: 15px;
  padding: 0;
}

// override
.navItem {
  position: relative;
  margin: 0;
  padding: 2px 0 2px 0;
  font-weight: $font-weight;

  span {
    padding-left: 3px;
  }
}

.navLink {
  padding: 5px 10px 5px $offsetMenuText;
  font-weight: $font-weight;
}

.navLink:hover,
.navLinkActive {
  font-weight: $font-weight;
}
