$details-title: #939393;

.bordered {
  border: none;
}

.unpad {
  padding: 0 !important;
}

.container {
  padding: 13px 2px 13px 2px;

  button {
    font-size: 12px;
  }
}

.centeredCol {
  display: inline-flex;
  align-items: center;
  min-height: 100%;
}

.valueCol {
  padding-left: 0;
}

.detailsTitle {
  color: $details-title;
  @extend .centeredCol;
}
