@import '../../../../styles/helpers/variables.scss';
@import '../../../../styles/helpers/common/imageHolder.scss';

$content-font-name: 17px;
$content-font-small: 10px;
$content-icon-size: 20px;
$content-item-text: white;
$content-item-bg: #6e6e6e;
$content-item-selected: $common-button-link;
$content-selected-overlayLevel: 0.4;
$block-content: #d9534f;

%contentShadow {
  box-shadow: inset 0 0 0 200px rgba(0, 0, 0, $content-selected-overlayLevel);
}

%contentItem {
  display: flex;
  flex-direction: column;
  width: 100%;
  padding-bottom: 100%;
  @include setItemBackground($content-item-bg, cover);

  cursor: pointer;
}

.contentItem {
  @extend %contentItem;
}

.contentItemUnselected {
  @extend %contentItem;
  @extend %contentShadow;
}

.contentItemSelected {
  @extend %contentItem;
  @extend %contentShadow;
}

.contentItemSelectedRow {
  margin-top: 5px;
}

.contentCheckCol {
  margin-left: 3px;
}

.contentDeleteCol {
  margin-right: 3px;
  text-align: right;
}

.contentDeleteItem {
  width: 43px;
  height: 23px;
  margin-top: 2px;
  margin-right: 9px;
  padding: 0;
  color: $content-item-text;
  font-size: 12px;
  text-align: center;
  background-color: $block-content;
  border: none;
  border-radius: 4px;

  &:hover,
  &:active,
  &:focus {
    background-color: $block-content;
  }
}

.contentOpenArea {
  position: absolute;
  display: block;
  width: 100%;
  height: 100%;
}

.contentHeader {
  position: absolute;
  left: 0;
  display: block;
  width: 100%;
  color: $content-item-text;
}

@media screen and (min-width: 900px) and (max-width: 1100px) {
  .contentCheckCol {
    text-align: center;
  }

  .contentDeleteCol {
    padding-top: 10px;
    text-align: center;
  }
}
