@mixin setItemBackground($bgColor: #1e1e1e, $size: contain) {
  background: {
    position: center;
    color: $bgColor;
    repeat: no-repeat;
    size: $size;
  }
}

%albumItemText {
  text-shadow: 0.5px 0.5px #a3a3a3;
}
