.importButton {
  margin: 0;
  padding: 10px 15px;
  color: #17a2b8 !important;
  border: 1px solid #17a2b8;
  border-radius: 0.25rem;
  cursor: pointer;
}

.paginationWrap {
  display: flex;
  justify-content: end;

  div {
    margin: 7px 0 0 !important;
  }
}
