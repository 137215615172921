@import '../../../../styles/helpers/variables.scss';

.selectBack {
  color: $back-button-main;
}

.selectBackActive {
  background: transparent;
}

.backTitle {
  padding-left: 6px;
}
