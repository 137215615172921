$row-odd-color: #f9f9f9;
$row-even-color: #fff;
$row-highlight: #ebebeb;

tr.userTableRow:nth-child(odd) {
  background: $row-odd-color;
  box-shadow: 0 -1px 0 0 $row-highlight;

  &:hover {
    background: $row-highlight;
  }
}

tr.userTableRow:nth-child(even) {
  background: $row-even-color;
  box-shadow: 0 -1px 0 0 $row-highlight;

  &:hover {
    background: $row-highlight;
  }
}

.userTableRow {
  & :hover {
    background: none;
  }
}
