@import '../variables.scss';

$profile-page-title: #3e4770;
$profile-page-value: #a3a3a3;
$profile-page-link: #3e9cf3;
$profile-page-header-fontSize: 34px;
$profile-details-fontSize: $font-size-main;
$profile-details-border: #d1d1d1;
$profile-user-icon: #bfbfbf;
$profile-album-item: #6e6e6e;

%profileTitle {
  color: $profile-page-title;
  font-weight: bold;
}

.profileHeader {
  @extend %profileTitle;

  font-size: $profile-page-header-fontSize;
}

.profileContextTitle {
  @extend %profileTitle;

  font-size: $profile-details-fontSize;
}

.profileStatColumn {
  text-align: center;

  & div:nth-child(odd) {
    @extend %profileTitle;

    color: $profile-page-value;
  }

  & div:nth-child(even) {
    @extend %profileTitle;

    color: $profile-page-title;
  }
}

.profileDetailsContainer {
  border-left: none;
}

%pictureProfileItemAlbumBg {
  background-image: radial-gradient(circle at 82% 18%, rgba(255, 255, 255, 0.44), rgba(255, 255, 255, 0) 59%), linear-gradient(to bottom, #c0c0c0, #c0c0c0);
}

%profileOutLink {
  color: $profile-page-link;
}
