@import '../../../../styles/helpers/variables.scss';
$text-main-bg: #c9c9c9;
$label-color: #939393;

.errorHolder {
  padding-left: 3px;
}

.errorField {
  color: $form-error-text-color;
  transform: translateX(5px) !important;
}

.textLabel {
  margin-bottom: 0.3rem;
  color: $label-color;
}

.textInputWithIcon {
  padding-left: 30px;
}

.textIcon {
  position: absolute;
  top: 34px;
  left: 24px;
  color: $text-main-bg;
}

.textInput {
  &:focus,
  &:active {
    font-size: $font-size-large;
  }
}
