@import '../../../styles/helpers/tables/userList.scss';

$border-color: #eceeef;

.devicesWrapper {
  margin: 40px 0 50px 0;
  margin-left: -15px;
  padding: 0;
}

.userTableHeader {
  border-top: 1px solid $border-color;
}

.userTableRow {
  border: 1px solid $border-color;

  & :hover {
    background: $seletedRow;
    cursor: pointer;
  }
}

.deleteButton {
  float: right;

  &:hover {
    background-color: #dc3545;
  }
}
