// section: user management
// form: user manage
$user-manage-title: grey;
$common-delete-color: #d80037;
$user-manage-blockUser: #d80037;
$user-manage-checkbox: white;
$user-manage-checkbox-bg: white;
$back-button-main: #0275d8;

// errors
$form-error-text-color: #d92008;
$form-error-blockBg: #f2dede;
$form-error-blockText: #a94442;
