$danger-title-color: #c9302c;

.dangerArea {
  margin-top: 50px;
  margin-bottom: 30px;
}

.dangerAreaTitle {
  margin: 0;
  margin-left: -19px;
  color: $danger-title-color !important;
  text-transform: uppercase;
}
