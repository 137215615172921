@import '../../../styles/helpers/tables/alertStatus.scss';
@import '../../../styles/helpers/tables/userList.scss';

.totalAmount {
  width: fit-content;
  margin-bottom: 10px;
  padding: 15px 10px 5px 12px;
}

.validatedBadge {
  display: inline-block;
  transform: translateY(-1px);
}
