.switch {
  input[type=checkbox] {
    display: none;
    width: 0;
    height: 0;
  }

  label {
    position: relative;
    display: block;
    width: 40px;
    height: 16px;
    text-indent: -9999px;
    background: grey;
    border-radius: 100px;
    cursor: pointer;

    &:hover {
      background: grey;
    }
  }

  label::after {
    position: absolute;
    top: 1px;
    left: 1px;
    width: 14px;
    height: 14px;
    background: #fff;
    border-radius: 90px;
    transition: 0.3s;
    content: '';
  }

  input:checked + label {
    background: #bada55;
  }

  input:checked + label::after {
    left: calc(100% - 1px);
    transform: translateX(-100%);
  }

  label:active::after {
    width: 15px;
  }
}
