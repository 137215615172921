$details-title: #939393;

.bordered {
  border: solid 1px #eceeef;
}

.unpad {
  padding: 0 !important;
}

.centeredCol {
  display: inline-flex;
  align-items: center;
  min-height: 100%;
}

.container {
  min-height: 50px;
  padding: 9px 2px 9px 2px;
}

.valueCol {
  line-height: 30px;
  vertical-align: middle;
}

.containerFloat {
  padding: 9px 2px 9px 2px;
}

.valueColFloat {
  vertical-align: middle;
}

.detailsTitle {
  color: $details-title;
  @extend .centeredCol;
}
