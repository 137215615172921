@import '../../helpers/variables.scss';

$detailsBg: white;
$details-close: grey;

.detailsContainer {
  height: 100%;
  background: $detailsBg;

  button {
    font-weight: bold;
  }
}

.detailsRow {
  margin-left: 3px;
}

.detailsClose {
  text-align: right;

  button {
    margin-top: 5px;
    color: $details-close;
    font-size: 28px;
    text-align: right;
    background: transparent;
    border: none;
  }
}

.detailsFooter {
  position: absolute;
  bottom: 34px;
  width: 100%;
  text-align: center;
}

.detailsFooterBtns {
  text-align: center;

  button {
    margin-right: 15px;
    margin-left: 15px;
  }
}

.linkAlbum {
  color: $common-link;
  cursor: pointer;
}

@media screen and (max-width: map_get($grid_breakpoints, xl)) {
  .detailsContainer {
    font-size: 12px;

    button {
      font-size: 12px;
    }
  }

  .detailsFooter {
    position: initial;
    bottom: 0;
    width: 100%;
  }
}

@media screen and (max-width: map_get($grid_breakpoints, lg)) {
  .detailsRow {
    margin-left: 0;
  }

  .detailsContainer {
    font-size: 10px;

    button {
      font-size: 10px;
    }
  }

  .detailsFooter {
    position: initial;
    bottom: 0;
    width: 100%;
    text-align: center;
  }
}

@media screen and (min-width: 500px) and (max-height: 1100px) {
  .detailsFooterBtns {
    button {
      margin-bottom: 10px;
    }
  }
}
