@import '../../../../styles/helpers/variables.scss';

.fieldCheckbox {
  &::placeholder {
    color: $dark-color;
  }
}

.eyeCheckbox {
  position: absolute;
  top: 8px;
  right: 30px;
  width: 20px;
  height: 20px;
  font-weight: bold;
}

%customCheckboxA {
  position: absolute;
  top: 0;
  right: 0;
  display: block;
  width: 20px;
  height: 20px;
  color: $user-manage-checkbox;
  text-align: center;
  background: $user-manage-checkbox-bg;
}

input[type="checkbox"]:hover {
  cursor: pointer;
}

input[type="checkbox"]::before {
  @extend %customCheckboxA;

  border: 1px #ccc solid;
  content: "";
}

input[type="checkbox"]:checked::before {
  @extend %customCheckboxA;

  // content: "✓";
  background: url("../../../../assets/form/checkbox-on.svg");
  background-size: contain;
  border: none;
}

input[type="checkbox"]:disabled::before {
  @extend %customCheckboxA;
}
