@import '../../../styles/helpers/variables.scss';

$error-container-size: 16px;

.errorContainer {
  display: block;
  justify-content: center;
  width: 100%;
  height: 50px;
  color: $form-error-blockText;
  text-align: center;
  background: $form-error-blockBg;
  border-radius: 4px;

  div {
    position: absolute;
    top: 50%;
    left: 50%;
    color: $form-error-blockText;
    font-size: $error-container-size;
    text-align: center;
    transform: translate(-50%, -50%);
  }
}
