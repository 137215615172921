@import '../../../../styles/helpers/variables.scss';

$button-color: $common-button-link;
$delete-color: #d92008;

%contentCommonButton {
  color: $button-color;
  text-decoration: underline;
}

.contentSelectAll {
  @extend %contentCommonButton;
}

.contentIgnore {
  @extend %contentCommonButton;
}

.contentDelete {
  @extend %contentCommonButton;

  color: $delete-color;
}

.contentMenuList {
  display: inline-block;
  margin: 0;
  padding: 0;

  li {
    display: inline-block;
  }
}
