@import '../../../styles/helpers/variables.scss';

$modal-body-bg: #f5f5f5;

%centered {
  width: 100%;
  margin: auto;
  text-align: center;
}

.modal {
  z-index: 9999;
}

.modalHeader {
  @extend %centered;

  padding: 1.3rem;
  border: none;
}

.modalTitle {
  display: block;
  width: 100%;
  margin-right: auto;
  margin-left: auto;
  padding-left: 6%; // since X button shifts centered block
  font-weight: bold;
  text-align: center;
}

.modalBody {
  background: $modal-body-bg;
}

.fieldTitle {
  color: $user-manage-title;
}

.modalFooter {
  display: block !important;
  border-top: none;
}

.blockUser {
  color: $user-manage-blockUser;

  &:hover {
    color: $user-manage-blockUser;
    background: none;
  }
}

%footerCommonBtn {
  font-weight: bold;
}

.modalFooterBtns {
  @extend %footerCommonBtn;
}

.modalBtnCancel {
  @extend %footerCommonBtn;

  margin-right: 10px;
}

.modalFooterButtons {
  text-align: center;

  & button {
    margin: 5px 0 5px 5px;
  }

  & button:nth-child(odd) {
    margin-right: 10px;
  }
}
