@import '../common/imageHolder.scss';

$picture-holder: #1e1e1e;
$picture-holder-height: 700px;

.modalBody {
  display: block;
  margin: 0;
  padding: 0;
}

.albumPictureHolder {
  display: block;
  width: 100%;
  height: 100%;
  @include setItemBackground($picture-holder, contain);
}

.albumPictureDeleted {
  position: absolute;
  z-index: 200;
  width: 100%;
  height: 50px;
}

.albumPictureRow {
  min-height: $picture-holder-height;
  margin: 0;
  padding: 0;
  background: $picture-holder;
}

.albumPictureCol {
  margin: 0;
  padding: 0;
}
