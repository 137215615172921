$tag-color: #3e4770;
$tag-border: #e9e9e9;
$button-link: #0275d8;

.TagEditor {
  .main {
    width: 100%;
    min-height: 50px;
    padding: 5px;
    border: 1px solid $tag-border;
    border-radius: 4px;
  }

  .tag {
    position: relative;
    margin: 3px;
    padding: 0 10px 0 10px;
    color: $tag-color;
    font-weight: bold;
    font-size: 16px;
    line-height: 30px;
    background: rgba(255, 255, 255, 0.7);
    border: 1px solid $tag-border;
    border-radius: 15px;
  }

  .inputs {
    margin-top: 10px;
  }

  input {
    position: relative;
    top: -1px;
    height: 30px;
    padding-left: 3px;
    border: none;
  }

  .buttons {
    margin-top: 25px;
    margin-left: 10px;
    padding-left: 0;

    li {
      display: inline-block;
      margin-right: 10px;
    }
  }
}
