@import '../../../styles/helpers/themes/themeMain/main.scss';
@import '../../../styles/helpers/themes/themeMain/sidebar.scss';

.nav {
  display: flex;
  flex: 1 0;
  flex-direction: column;
  justify-content: flex-start;
  width: 17vw;
  height: 100%;
  min-height: 100vh;
  margin: 0;
  padding: 17px 0 10px 0;
  list-style: none;
  background: $sidebar-bg;
}

.navItem {
  position: relative;
  margin: 0;
  padding-top: 0;
  padding-bottom: 0;

  span {
    padding-left: 7px;
  }
}

%navCommonLink {
  display: block;
  padding: 7px 0 7px $sidebar-item-leftOffset;
  color: $sidebar-menu-item;
  font-weight: $font-weight-bold;
  font-size: $font-size-main;
  letter-spacing: 0.1px;
  background-color: transparent;
}

.navLink {
  @extend %navCommonLink;
}

.navInactiveLink {
  @extend %navCommonLink;

  &:hover,
  &:focus {
    color: $sidebar-menu-item;
    background-color: transparent;
  }

  cursor: initial;
}

.navLinkDisabled {
  @extend .navLink;

  color: $sidebar-menu-item-disabled;
  user-select: none;
  pointer-events: none;
}

.navLink:hover,
.navLinkActive {
  color: $sidebar-menu-item-active;
  font-weight: bold;
  text-decoration: none;
  background: $sidebar-menu-item-activeBg;
}

%navTextLink {
  padding-right: 10px;
  vertical-align: middle;
}

.navLinkManagement {
  @extend %navTextLink;
}

.navLinkReport {
  @extend %navTextLink;
}

.navLinkDiscovery {
  @extend %navTextLink;

  line-height: 20px;
}
