@import '../../styles/helpers/variables.scss';

$button-color: $common-button-link;

.contentSelectRow {
  margin-top: 40px;
  margin-left: 0;
}

.contentSelectRowCol {
  max-width: fit-content !important;
  padding-right: 0;
  padding-left: 5px;
  line-height: 36px;
}

.contentSelectAll {
  color: $button-color;
  text-decoration: underline;
}

.contentRow {
  margin: 6px 0 0 0;
}

.contentCol {
  padding: 6px;
}

.menuCol {
  text-align: right;
}
