@import '../commonLayout.scss';

.layoutContainer {
  @extend %commonContainer;

  min-height: 100vh;

  & h3 {
    padding: 5px;
  }
}

.layoutHeader {
  @extend %commonHeader;

  height: 50px;
  padding: 3px 0 3px 0;
}

.layoutSidebar {
  background-color: $layout-border-bg;
}

.logo {
  margin-top: 4px;
  margin-left: 10px;
}

.layoutHeaderNav {
  float: right;
  margin-top: 6px;
  margin-left: -20px;
  text-align: right;
}

.layoutContent {
  @extend %commonContent;
}

.layoutFooter {
  display: none;
  font-size: 10pt;
  text-align: center;
}
