.tagsBox {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  gap: 5px;
  align-items: center;
  width: 100%;
  padding: 10px;
  border: 1px solid #aaa;
  border-radius: 4px;

  input {
    width: 150px !important;
    border: none !important;
  }

  .inputBox {
    position: relative;
    flex: 0 0 0;
  }

  .tagBadge {
    display: flex;
    gap: 3px;
    align-items: center;
    height: 33px;
    padding: 5px;
    color: #4e4e4e;
    background: #e9e9e9;
    border-radius: 4px;
    cursor: pointer;

    svg {
      color: #4e4e4e;
      font-size: 16px;
    }

    &:hover {
      background: #d1d1d1;
    }
  }

  .tagsList {
    position: absolute;
    top: 34px;
    left: 0;
    z-index: 10;
    width: 100%;
    max-height: 170px;
    padding: 0;
    overflow-y: auto;
    background-color: #fff;
    border: 1px solid #eaeaea;
    border-radius: 0 0 4px 4px;
  }

  .tagsListItem {
    padding: 10px;
    list-style: none;
    cursor: pointer;

    &:not(:last-child) {
      margin-bottom: 5px;
    }

    &:not(.description):hover {
      background-color: #eaeaea;
    }

    &.description {
      color: #4e4e4e;
      font-size: 13px;
      cursor: default;
    }

    svg {
      color: #28a745;
      font-size: 16px;
    }
  }

  .loader {
    display: block;
    width: 50px;
    margin: 10px auto;
  }
}

.removeButton {
  color: red;
  font-size: 14px;
  background-color: transparent;
  border: none;
  cursor: pointer;
}
