@import 'helpers/variables.scss';
@import 'base/base.scss';

body {
  position: relative;
  margin: 0;
  overflow-x: hidden;
}

a {
  color: $common-link;

  &:hover {
    color: $common-link-hover;
  }
}
