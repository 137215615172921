@import '../../../styles/helpers/tables/userList.scss';

.button {
  font-size: 14px;
}

.name {
  width: 90%;
}

.alignRight {
  text-align: right;
}

.addBtn {
  padding: 9px 24px;
  font-weight: bold;
  font-size: 14px;
}
