@import './pages.scss';
@import './form.scss';

// Colors

$brand-color: #5e61ff !default;
$danger-color: #f74028 !default;
$dark-color: #424242 !default;
$common-text-color: #2a303b;

$common-link: #005aff;
$common-button-link: #0275d8;
$common-link-hover: #003391;
$common-link-decoration: none;

$text-primary: #2b2b2b !default;

$bg-base: rgb(247, 247, 247) !default; /* f7f7f7 */
$placeholder-base: rgba(0, 0, 0, 0.44);

// Spacings & borders

$base-border-radius: 10px !default;
$small-border-radius: 4px !default;

// Typography
$base-font-family: 'OpenSans', helvetica, arial, sans-serif !default;

$font-size-large: 16px !default;
$font-size-main: 14px;
$font-size-tiny: 10px !default;

$font-weight-bold: 600 !default;

// Pagination

$pagination-main-margin: 40px 0 30px 0;

// Misc

$on-phone: 576px !default; // Landscape phones
$on-small-screen: 768px !default; // Small screen / tablet
$on-medium-screen: 992px !default; // Medium screen / desktop
$on-large-screen: 1200px !default; // Large screen / desktop

$header-height: 60px;
