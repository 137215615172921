@import '../variables.scss';
$title-list-color: $common-text-color;

.userList {
  list-style-type: none;
}

h3 {
  margin-top: 2px;
  margin-left: 10px;
  color: $title-list-color;
  font-weight: bold;
  font-size: $user-list-title;
}

$verificationDefault: $common-text-color;
$verificationApplied: #00af2b;
$seletedRow: #ebebeb;

%userVerfication {
  color: $verificationDefault;
}

.userVerificationDefault {
  @extend %userVerfication;
}

.userVerificationApplied {
  color: $verificationApplied;
}

.userTableHeader * {
  border: none !important;
}

.userTableCol {
  max-width: 500px;
  border: none !important;
}

.userTableRow {
  & :hover {
    background: $seletedRow;
    cursor: pointer;
  }
}
