$tag-item-border: #e9e9e9;
$tag-item-color: #3e4770;

.categoryView {
  position: absolute;
  top: 5%;
  display: block;
  transform: translateY(-5%);
}

.categoryViewItem {
  display: inline-block;
  width: fit-content;
  height: 32px;
  padding: 0 10px 9px 10px;
  color: $tag-item-color;
  font-weight: bold;
  font-size: 14px;
  border: 1px solid $tag-item-border;
  border-radius: 15px;
}
