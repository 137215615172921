@import '../../../../styles/helpers/variables.scss';

$active-linkBg: $common-button-link;
$normal-link: $common-button-link;
$disabled-link: #a3a3a3;
$disabled-link-border: #eceeef;
$normal-link-size: 14px;

.page-item .page-link {
  padding: 12px 15px 12px 15px;
  color: $normal-link;
  font-weight: 600;
  font-size: $normal-link-size;
  border: 1px solid #eceeef;
}

.page-item.active .page-link {
  background-color: $active-linkBg !important;
}

.page-item.disabled .page-link {
  color: $disabled-link;
  border: 1px solid $disabled-link-border;
}

.paginationBlocked {
  box-shadow:
    0 0 0 100px inset,
    0 0 5px grey;
  transition: box-shadow 0.2s;
}

.paginationContainer {
  margin: $pagination-main-margin;
}
