.manageUserForm {
  width: 104%;
  margin-left: -16px;
}

.storageCol {
  padding-right: 0;
  padding-left: 0;
}

.applyCol {
  padding-right: 0;
  text-align: right;
}
