@import '../commonLayout.scss';

.layoutContainer {
  @extend %commonContainer;
}

.layoutHeader {
  @extend %commonHeader;

  height: 50px;
}

.layoutContent {
  @extend %commonContent;
}
