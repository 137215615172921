@import '../../../styles/helpers/commonManagement/profile.scss';
@import '../../../styles/helpers/common/imageHolder.scss';

$profile-stat-main: 12px;
$profile-stat-number: 14px;

.userStatInstaAccount {
  @extend %profileOutLink;
}

.profileContextTitle {
  padding-bottom: 20px;
  padding-left: 15px;
}

.userStatLink {
  @extend %profileOutLink;

  padding-top: 10px;
}

.userProfileIcon {
  color: $profile-user-icon;
}

.userStatDescription {
  color: $profile-page-value;
}

.userAlbumsRow {
  min-height: 220px;
  margin: 10px 0 10px 0;
}

.userAlbumsCol {
  padding: 6px;
}

.userAlbumsEmpty {
  margin: 0;
  margin-left: -17px;
  padding: 0;
}

.userProfilePhoto {
  display: block;
  width: 80px;
  height: 80px;
  @include setItemBackground(transparent, cover);

  border-radius: 50px;
}

.profileNotFound {
  padding: 20px;
  color: red;
  font-weight: bold;
  font-size: 16px;
  text-align: center;
}

.profileStatColumn {
  margin: 0;
  padding: 0;
  font-size: $profile-stat-main;

  & div:nth-child(odd) {
    width: 100%;
    font-size: $profile-stat-main;
  }

  & div:nth-child(even) {
    width: 100%;
    font-size: $profile-stat-number;
  }

  @media screen and (min-width: 1200px) {
    padding: 0 0 0 10px;
  }
}
