@import '../../../../styles/helpers/commonManagement/profile.scss';
@import '../../../../styles/helpers/animations/fade.scss';
@import '../../../../styles/helpers/common/texts.scss';
@import '../../../../styles/helpers/common/imageHolder.scss';
@import '../../content_approveblock.scss';

$content-font-name: 17px;
$content-font-small: 10px;
$content-icon-size: 20px;
$content-item-curve: 10px;
$content-item-text: white;
$content-item-gradient: #6e6e6e;
$content-item-gradientTo: #474747;
$content-item-headerIconBg: #f3f3f3;
$content-item-headerIconContent: #c2c2c2;

%contentItemBg {
  background-image: radial-gradient(circle at 82% 18%, rgba(255, 255, 255, 0.44), rgba(255, 255, 255, 0) 59%), linear-gradient(to bottom, $content-item-gradient, $content-item-gradientTo);
  border: solid 0.5px rgba(255, 255, 255, 0.22);
}

.contentItem {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 12vw;
  height: 12vw;
  overflow: hidden;
  border-radius: $content-item-curve;
  cursor: pointer;
  animation: fadein 2s;

  @include setItemBackground(transparent, cover);
  @extend %contentItemBg;

  @media screen and (max-width: map_get($grid_breakpoints, lg)) {
    width: 18vw;
    height: 18vw;
  }
  @media screen and (max-width: map_get($grid_breakpoints, md)) {
    width: 22vw;
    height: 22vw;
  }
}

.contentItemAvatar {
  display: inline-block;
  width: 20px;
  height: 20px;
  background-color: grey;
  background-repeat: no-repeat;
  background-size: cover;
  border-radius: 25px;
  transform: translateY(7px);
}

.contentControls {
  position: absolute;
  z-index: 300;
  display: block;
  width: 100%;
  height: 100%;
  font-size: 12px;
  opacity: 0;

  & div:nth-child(odd) {
    float: left;
    padding-top: 8px;
    padding-left: 7px;
    @extend %approveBtn;
  }

  & div:nth-child(even) {
    float: right;
    padding-top: 8px;
    padding-right: 7px;
    @extend %blockBtn;
  }

  .enterArea {
    position: absolute;
    bottom: 20px;
    display: block;
    width: 100%;
    height: 70%;
  }

  &:hover {
    opacity: 1;
  }

  @media screen and (min-width: 992px) and (max-width: 1090px) {
    & div:nth-child(odd) {
      float: center;
      width: 100px;
      height: 40px;
      padding-top: 8px;
      padding-left: 0;
      text-align: center;
    }

    & div:nth-child(even) {
      float: center;
      width: 100px;
      height: 40px;
      padding-top: 8px;
      padding-right: 0;
      text-align: left;
    }
  }
}

.contentHeader {
  display: block;
  width: 100%;
  margin: 5px 5px 5px 15px;
  color: $content-item-text;
  font-size: $content-font-small;

  & span:nth-child(odd) {
    display: inline-block;
    width: 20px;
    height: 20px;
    padding: 0;
    color: $content-item-headerIconBg;
    font-size: $content-icon-size;
    background: $content-item-headerIconContent;
    border-radius: 50%;
    transform: translateY(7px);

    svg {
      vertical-align: top;
    }
  }

  & span:nth-child(even) {
    padding-left: 7px;
  }
}

%bottomShadow {
  background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.36));
  border-bottom-right-radius: 5px;
  border-bottom-left-radius: 5px;
}

.albumName {
  width: 80%;
  @extend .shortText;
}

.contentFooter {
  position: absolute;
  bottom: 0;
  display: block;
  width: 100%;
  padding-bottom: 5px;
  padding-left: 10px;
  overflow: hidden;
  color: $content-item-text;
  @extend %bottomShadow;

  & div {
    @extend %albumItemText;
  }

  & div:nth-child(odd) {
    padding: 1px 5px 1px 5px;
    font-weight: bold;
    font-size: $content-font-name;
  }

  & div:nth-child(even) {
    padding: 1px 5px 3px 5px;
    font-weight: bold;
    font-size: $content-font-small;
  }
}
