@import '../../../styles/helpers/tables/alertStatus.scss';
@import '../../../styles/helpers/tables/userList.scss';
@import '../content_approveblock.scss';

.reportUsers {
  float: left;
  margin-top: 25px;
}

.reportUserWrapper {
  width: 65%;
}

.approveCol {
  @extend .userTableCol;

  width: 70px;

  @extend %approveBtn;
}

.blockCol {
  @extend .userTableCol;

  width: 70px;

  @extend %blockBtn;
}
