@import '../../helpers/themes/themeMain/alertStatus.scss';

%alertStatus {
  display: inline-block;
  margin-right: 2px;
  padding: 2px 9px 2px 9px;
  color: $alert-text-color;
  font-weight: bold;
  font-size: 12px;
  text-align: center;
  border-radius: 5px;
}

.alertStatusReported {
  @extend %alertStatus;

  background: $alert-warn-bg;
}

.alertStatusGot_2k {
  @extend %alertStatus;

  background: $alert-got2k-bg;
}

.alertStatusFollow_2k {
  @extend %alertStatus;

  background: $alert-follow2k-bg;
}
