$button-bg: white;
$button-title: #373a3c;
$border-color: #ccc;

.main {
  padding: 8px 15px 8px 15px;
  color: $button-title;
  background: $button-bg;
  border: 1px solid $border-color;
}

.mainBold {
  font-weight: bold;
}

.mainSmall {
  padding: 5px 15px 5px 15px !important;
}

.outlineHoverNone {
  &:focus,
  &:hover {
    background: none !important;
    border-color: transparent !important;
    outline: none !important;
    box-shadow: none !important;
  }
}
