@import '../../../../styles/helpers/modal/main.scss';

.modalTitle {
  width: 100%;
  margin: auto;
  text-align: center;
}

.modalContent {
  padding: 0;
}

.modalFooterButtons {
  padding-right: 0;
}
