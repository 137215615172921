@import '../../../styles/helpers/commonManagement/details.scss';
@import '../../../styles/helpers/tables/alertStatus.scss';
@import '../../../styles/helpers/common/imageHolder.scss';

.albumDetailsDelete {
  color: $common-delete-color;
  background: none;
}

.albumDetailsCover {
  display: block;
  width: 82px;
  height: 82px;
  @include setItemBackground(#7b7b7b, cover);
}

.albumDetailCoverDelete {
  display: block;
  margin-top: auto;
  padding-right: 0;
  text-align: right;
  @include alignBtnRight(0, 4px);
}

.albumMemberBtnCol {
  padding: 0;
}

.albumDetailsShare {
  color: $exist-color;
}

.albumDetailsCategoryCol {
  @include alignBtnRight(0, 4px);

  position: absolute;
  top: 50%;
  right: 0;
  transform: translateY(-50%);
}

.albumDetailsMemberList {
  height: 200px;
  margin: 0;
  padding: 0;
  overflow-y: scroll;

  li {
    list-style-type: none;
  }
}
