.socialMediaItems {
  .socialMediaItem {
    display: flex;
    gap: 10px;
    margin: 0 0 10px;
  }
}

.removeButton {
  margin: 0 0 15px 10px;
  color: red;
  font-size: 14px;
  background-color: transparent;
  border: none;
  cursor: pointer;
}
