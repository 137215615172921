@import '../helpers/fonts/openSans.scss';
@import "../helpers/variables";

html,
body,
#root {
  box-sizing: border-box;
  height: 100%;
}

*,
*::before,
*::after {
  box-sizing: inherit;
}

:focus {
  outline: none;
}

body {
  color: $text-primary;
  font-size: $font-size-large;
  font-family: $base-font-family;
  background-color: $bg-base;
  -webkit-font-smoothing: antialiased;
  text-rendering: optimizeLegibility;
}

a {
  color: $brand-color;
  text-decoration: none;
}

img {
  max-width: 100%;
  height: auto;
}

strong {
  font-weight: 800;
}
