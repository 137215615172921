.rowEditor {
  position: fixed;
  z-index: 10;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(0, 0, 0, 0.7);
  inset: 0;

  .rowEditorBox {
    width: 600px;
    max-height: 80vh;
    padding: 25px;
    overflow-y: auto;
    background: white;
    border-radius: 8px;

    label {
      display: block;
      margin-top: 10px;
    }

    input,
    textarea,
    select {
      width: 100%;
      padding: 6px;
      border: 1px solid #aaa;
      border-radius: 4px;
    }

    input,
    select {
      height: 40px;
    }
  }
}
