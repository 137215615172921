$content-body-bg: #f5f5f5;
$title-size: 18px;

.modal {
  z-index: 9999;
}

.modalHeader {
  padding: 1.3rem;
  text-align: center;
  border: none;
}

.modalTitle {
  display: block;
  width: 100%;
  margin-right: auto;
  margin-left: auto;
  font-weight: bold;
  font-size: $title-size;
}

.modalBody {
  padding-bottom: 0;
  text-align: left;
  background: $content-body-bg;
}

.modalFooter {
  display: block !important;
  padding-top: 9px;
  padding-bottom: 9px;
  border-top: none;
}

.modalFooterButtons {
  & button {
    margin: 5px 0 5px 5px;
  }

  & button:nth-child(odd) {
    margin-right: 10px;
  }
}
