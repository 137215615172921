@import '../../../styles/helpers/commonManagement/profile.scss';
@import '../../../styles/helpers/common/imageHolder.scss';
$item-bg: #f1f2f5;

.albumItem {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 11vw;
  @include setItemBackground($item-bg, cover);

  cursor: pointer;
  @extend %pictureProfileItemAlbumBg;
}

.albumItemLabel {
  display: block;
  width: 100%;
  margin-top: auto;
  margin-bottom: 3px;
  margin-left: 10px;
  color: white;
  font-size: $profile-details-fontSize;

  & div:nth-child(odd) {
    font-weight: bold;
  }
}
