@import '../../styles/helpers/variables.scss';

#app,
.App {
  height: 100%;
}

// global link style
a:hover {
  text-decoration: $common-link-decoration;
}
