.checkboxCol {
  padding-left: 0;
}

.selectCol {
  padding-right: 0;
  padding-left: 0;
}

.applyButtonCol {
  padding-right: 16px;
  padding-left: 0;
  text-align: center;

  button {
    margin-top: 4px;
    margin-right: 2px;
    vertical-align: middle;
  }

  @media screen and (max-width: 1024px) {
    padding-right: 0;

    button {
      margin-right: 0;
    }
  }

  @media screen and (min-width: 1700px) {
    padding-right: 3px;
  }
}
