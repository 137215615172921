@import '../../../../styles/helpers/variables.scss';
@import '../../../../styles/helpers/commonManagement/profile.scss';
@import '../../../../styles/helpers/animations/fade.scss';
@import '../../../../styles/helpers/common/imageHolder.scss';

$label-color: white;

.userAlbumsItem {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 12vw;
  border-radius: 5px;
  cursor: pointer;
  animation: fadein 2s;
  @include setItemBackground(transparent, cover);
  @extend %pictureProfileItemAlbumBg;
}

.userPrivateItem {
  cursor: default;
  opacity: 1;

  a {
    color: $common-button-link;
    font-weight: bold;
  }
}

.sharedLink {
  color: $label-color;
  text-decoration: underline;
}

.visibilityIcon {
  padding: 5px;
}

%bottomShadow {
  background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.36));
  border-radius: 5px;
}

.userAlbumsItemLabel {
  display: block;
  width: 100%;
  margin-top: auto;
  padding-bottom: 10px;
  padding-left: 10px;
  color: $label-color;
  font-size: $profile-details-fontSize;
  @extend %albumItemText;
  @extend %bottomShadow;

  & div:nth-child(odd) {
    font-weight: bold;
  }
}

.btn {
  position: absolute;
  right: 15px;
  padding: 2px 9px;
  color: #fff;
  font-weight: bold;
  font-size: 10px;
  border: none;
  border-radius: 50px;
  outline: none !important;
  transition: all ease 0.5s;

  &.btnCopy {
    top: 15px;
    background: #0275d8;
  }

  &.btnRecal {
    top: 38px;
    background: #9f3fe0;
  }

  &.active {
    background: #20a118;
  }
}

.sharedUser {
  max-width: 100px;
  padding: 0;
  overflow: hidden;
  color: #fff;
  font-weight: bold;
  white-space: nowrap;
  text-decoration: underline;
  text-overflow: ellipsis;
  background-color: transparent;
  border: none;
}
