.nonAuthorizedContainer {
  h3 {
    margin-top: 20px;
  }
}

.loginLink {
  padding-left: 10px;
  font-size: 18px;
}
