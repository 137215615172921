.crmFilter {
  position: fixed;
  top: 0;
  right: 0;
  z-index: 10;
  width: 350px;
  height: 100vh;
  padding: 25px;
  background: #fff;
  animation: slideInRight 0.5s ease 1;

  select {
    width: 100%;
    padding: 10px;
    color: #404040;
    background: url("../../../../images/selectArrow.svg") no-repeat 90% center;
    border-color: #aaa;
    border-radius: 4px;
    cursor: pointer;
    appearance: none;
  }

  button {
    cursor: pointer;
  }
}

.crmFilterBg {
  position: fixed;
  z-index: 9;
  background-color: rgba(0, 0, 0, 0.2);
  inset: 0;
}

@keyframes slideInRight {
  from {
    transform: translate3d(100%, 0, 0);
    visibility: visible;
  }

  to {
    transform: translate3d(0, 0, 0);
  }
}
