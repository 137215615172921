@import '../styles/helpers/themes/themeMain/layout.scss';

%commonContainer {
  width: 100%;
  padding: 0;
  background: $layout-content-bg;
}

%commonHeader {
  box-sizing: border-box;
  width: 100%;
  padding: 10px 0 10px 0;
  background-color: $header-common-bg;
  border-top: 1px rgba(#000, 0.12) solid;

  & span {
    color: $header-logo;
    font-weight: bold;
  }
}

%commonContent {
  min-height: 200px;
  border: 1px dashed $layout-border-bg;
}

.logo {
  display: flex;
  align-items: center;
  min-width: 190px;
  height: 100%;
  margin-left: 20px;

  .img {
    width: auto;
    height: 22px;
  }
}

.logoText {
  margin-top: 2px;
  margin-left: 10px;
}
