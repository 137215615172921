@import '../../../../styles/helpers/variables.scss';
$text-main-bg: #c9c9c9;
$label-color: #939393;
$text-color: #373a3c;

.errorHolder {
  padding-left: 3px;
}

.errorField {
  color: $form-error-text-color;
  transform: translateX(5px) !important;
}

.textLabel {
  margin-bottom: 0.1rem;
  color: $text-color;
}

.textAreaNonResizable {
  resize: none;
}
