@import '../../../styles/helpers/tables/userList.scss';

$border-color: #eceeef;

.featureTable {
  width: 100%;

  td,
  th {
    padding: 15px;
    border: 1px solid $border-color;

    &.sm {
      width: 105px;
    }

    div {
      width: 42px;
      margin: 0 auto;
    }
  }
}

.featureOn {
  color: rgb(32, 161, 24);
}

.featureOff {
  color: rgb(163, 163, 163);
}

@media screen and (min-width: 1700px) {
  .devicesWrapper {
    width: 39vw;
  }
}

@media screen and (min-width: 1900px) {
  .devicesWrapper {
    width: 38.8vw;
  }
}
