@import '../../../../styles/helpers/variables.scss';

$alert-background: #fcf8e3;
$alert-border-color: #faf2cc;
$alert-text-color: #8a6d3b;

$loading-background: #fff;
$loading-text-color: #a3a3a3;
$text-size: 18px;

%baseContainer {
  width: 97%;
  margin-left: 10px;
  padding: 20px;
  padding-left: 3px;
  color: $common-text-color;
  font-weight: bold;
  font-size: $text-size;
  background: $alert-background;
  border: solid 1px $alert-border-color;
  border-radius: 4px;
}

.noticeContainer {
  @extend %baseContainer;
}

.loadingContainer {
  @extend %baseContainer;

  color: $loading-text-color;
  background: $loading-background;
  border: none;
}

.alertContainer {
  @extend %baseContainer;

  color: $alert-text-color;
  background: $alert-background;
  border: solid 1px $alert-border-color;
}
