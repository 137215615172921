@import '../../styles/helpers/commonManagement/profile.scss';

$media-count-bg: #3e4770;
$media-count-color: white;

.albumsMediaTitle {
  padding-left: 22px;
}

.albumsRow {
  min-height: 220px;
  margin: 10px 0 10px 0;
}

.albumsCol {
  padding: 1.5px;
}

.albumName {
  h3 {
    margin-left: 0;
  }
}

.albumItemsEmpty {
  margin: 0;
  margin-left: -10px;
  padding: 0;
}

.albumMediaCount {
  width: max-content;
  margin-left: 4px;
  // transform: translate(43px, -19px);
  padding: 1px 5px 0 5px;
  color: $media-count-color;
  font-weight: 600;
  font-size: 10px;
  letter-spacing: 0.24px;
  text-align: center;
  background: $media-count-bg;
  border-radius: 8px;
}
