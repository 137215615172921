@import '../../../../styles/helpers/commonManagement/details.scss';

.verifiedStatus {
  color: $details-verified;
}

.emailBtn {
  margin-top: auto;
  margin-bottom: auto;
  transform: translateX(10px);
  @include alignBtnRight(2px, 3px);
}

.detailsTitle {
  padding-left: 2px;
}

.detailsPassword {
  color: $exist-color;
}

.detailsProfileContainer {
  @extend .detailsContainer;

  margin-top: 10px;
  margin-left: -14px;
}

.emailCol {
  margin: 0;
  padding: 0;

  input[type="text"] {
    padding-top: 10px;
    padding-bottom: 8px;
    padding-left: 0;
    color: $details-text;
    border: 1px solid #ccc;
  }

  input[type="text"]:disabled {
    border: 1px solid white !important;
  }
}

.verifyTitleCol {
  margin-top: auto;
  margin-bottom: auto;
}

.passowordBtnCol {
  @include alignBtnRight(2px, 6px);
}

.verifyBtnCol {
  @include alignBtnRight(2px, 6px);
}

.modal {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1050;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.4);
}

.modalBox {
  position: absolute;
  top: 50%;
  left: 50%;
  display: flex;
  justify-content: space-between;
  width: 90%;
  min-width: 300px;
  max-width: 420px;
  padding: 50px;
  background-color: #fff;
  border-radius: 12px;
  transform: translate(-50%, -50%);

  input,
  select {
    padding: 5px 10px;
  }
}

.closeBTN {
  position: absolute;
  top: 10px;
  left: 10px;
  background: #fff;
  border: none;
  outline: none;
  cursor: pointer;
}

.changeBaseStorageBTN {
  font-size: 12px;
}
