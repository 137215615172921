@import '../../styles/helpers/tables/userList.scss';
@import './actionHistoryTableOverride.scss';

.actionHistoryContainer {
  margin: 15px 0;
  padding: 0;
}

.noResults {
  margin-top: 20px;
}
