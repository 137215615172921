$button-bg: #c9302c;
$button-title: white;

.main {
  padding: 8px 15px 8px 15px;
  color: $button-title;
  background: $button-bg;
}

.mainBold {
  font-weight: bold;
}
