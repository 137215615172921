$row-title-color: #606d84;

.rowTitle {
  margin-bottom: 0 !important;
  padding-bottom: 0;

  h3 {
    margin: 0;
    color: $row-title-color;
    text-transform: uppercase;
  }
}
