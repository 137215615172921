@import '../../styles/helpers/variables.scss';

$title-color: $common-text-color;
$title-text-size: 36px;

.signInContainer {
  margin-top: 30px;
  margin-bottom: 100px;
  border-color: transparent;
}

.signInTitle {
  margin-top: 100px;
  padding-left: 30px;
  color: $title-color;

  h3 {
    font-weight: 600;
    font-size: $title-text-size;
  }
}

.signInSubmitHolder {
  float: right;
  padding: 0 0 30px 0;
  text-align: right;

  button {
    margin-right: 12px;
  }
}

.signInErrorRow {
  margin-bottom: 20px;
}

.signInErrorCol {
  width: 300px !important;
  padding: 0 35px 0 20px;
}
