$approve-hover: #05ac05;
$block-hover: #ff5f5b;
$button-size: 12px;

%buttonHover {
  span {
    &:hover {
      background: none !important;
    }
  }
}

%approveBtn {
  button {
    @extend %buttonHover;

    font-size: $button-size;

    &:hover {
      background: $approve-hover !important;
    }
  }
}

%blockBtn {
  button {
    @extend %buttonHover;

    font-size: $button-size;

    &:hover {
      background: $block-hover !important;
    }
  }
}
