@import "../../../../styles/helpers/variables";

$font-lg: 28px;
$font-md: 20px;
$font-xs: 15px;
$label: grey;

.detailsClose {
  text-align: right;

  button {
    margin-top: 5px;
    margin-right: -5px;
    color: $label;
    font-size: $font-lg;
    text-align: right;
    background: transparent;
    border: none;
  }
}

// max-width: 1200px {
@media screen and (max-width: map_get($grid_breakpoints, xl)) {
  .detailsClose {
    button {
      margin-right: -10px;
      font-size: $font-md;
    }
  }
}

// max-width: 992px {
@media screen and (max-width: map_get($grid_breakpoints, lg)) {
  .detailsClose {
    button {
      margin-right: -15px;
      font-size: $font-xs;
    }
  }
}

// max-width: 992px {
@media screen and (max-width: map_get($grid_breakpoints, md)) {
  .detailsClose {
    button {
      margin-right: -12px;
    }
  }
}
