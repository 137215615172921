$disabled-color: #fff;

input[type="text"]:disabled {
  background: $disabled-color;
}

textarea:disabled {
  background: $disabled-color !important;
}

label {
  color: #a3a3a3 !important;
}
